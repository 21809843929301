import { render, staticRenderFns } from "./Carousel.vue?vue&type=template&id=25d5964d&scoped=true&"
import script from "./Carousel.vue?vue&type=script&lang=js&"
export * from "./Carousel.vue?vue&type=script&lang=js&"
import style0 from "./Carousel.vue?vue&type=style&index=0&id=25d5964d&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d5964d",
  null
  
)

export default component.exports
import {QCarousel,QCarouselSlide,QCarouselControl,QBtn,QVirtualScroll,QResponsive,QCard,QImg,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCarousel,QCarouselSlide,QCarouselControl,QBtn,QVirtualScroll,QResponsive,QCard,QImg})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})

//
//
//
//
//
//
//

// import Map from "@/components/Map_leaflet";
import Map from "@/components/reabilitare_termica/Map_mapbox";

export default {
  name: "ReabilitareTermicaView",
  components: {
    Map,
  },
  data () {
    return {
      homepage: false,
    }
  }
};

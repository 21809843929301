import { render, staticRenderFns } from "./InfoPanel.vue?vue&type=template&id=1f1451a2&scoped=true&"
import script from "./InfoPanel.vue?vue&type=script&lang=js&"
export * from "./InfoPanel.vue?vue&type=script&lang=js&"
import style0 from "./InfoPanel.vue?vue&type=style&index=0&id=1f1451a2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f1451a2",
  null
  
)

export default component.exports
import {QDrawer,QList,QCard,QImg,QBtn,QCardSection,QMarkupTable,QTooltip,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDrawer,QList,QCard,QImg,QBtn,QCardSection,QMarkupTable,QTooltip})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import constants from "@/util/constants";

export default {
  props: [
    'map',
    'mapLoadedFlag'
  ],

  data() {
    return {
      dummyFilterText: '',
      // itemGroups: [
      //   {
      //     code: 'LIE_2020_1',
      //     label: 'LIE 2020',
      //     checked: true,
      //     open: true,
      //   },
      // ],
    };
  },

  computed: {
    items() {
      return this.$store.state.lie2020_1.items;
    },

    itemGroups() {
      return this.$store.state.lie2020_1.itemGroups;
    },

    filteredItems() {
      // return this.items;
      // console.log('@SearchPanel > filteredArray: ', this.$store.getters["lie2020_1/filteredArray"]);
      return this.$store.getters["lie2020_1/filteredArray"];
    },

    itemsCount() {
      return this.$store.state.lie2020_1.items &&
      this.$store.state.lie2020_1.items.features ?
          this.$store.state.lie2020_1.items.features.length : 0;
    },

    layersArr() {
      return this.$store.state.lie2020_1.itemGroups ?
          this.$store.state.lie2020_1.itemGroups[0].layers : [];
    },

    countFilteredList() {
      return this?.filteredItems?.length;
    },

    itemsColors() {
      return this.$store.state.lie2020_1.itemsInfoPanelColors;
    },

    selectedItem() {
      return this.$store.state.lie2020_1.selectedItem;
    },

    filtruRiscSeismic: {
      get() {
        return this.$store.state.lie2020_1.filtruRiscSeismic || [];
      },
      set(value) {
        this.$store.dispatch("lie2020_1/updateFiltruRiscSeismic", {value, map: this.map});
      }
    },

    filtruRiscSeismicToate: {
      get() {
        return this.$store.state.lie2020_1.filtruRiscSeismicToate || false;
      },
      set(value) {
        this.$store.dispatch("lie2020_1/updateFiltruRiscSeismicToate", {value, map: this.map});
      }
    },

    filtruLMI: {
      get() {
        return this.$store.state.lie2020_1.filtruLMI || [];
      },
      set(value) {
        this.$store.dispatch("lie2020_1/updateFiltruLMI", {value});
      }
    },

    filtruLMIToate: {
      get() {
        return this.$store.state.lie2020_1.filtruLMIToate || [];
      },
      set(value) {
        this.$store.dispatch("lie2020_1/updateFiltruLMIToate", {value});
      }
    },
  },

  methods: {
    // @list select item
    async selectItem(clickedItem) {
      const desktopFlag = this.$q.platform.is.desktop;
      // console.log('@searchPanel > clickedItem: ', clickedItem);

      // deselect previous selection
      const previousSelectedItem = this.$store.state.lie2020_1.selectedItem;
      // console.log('previousSelectedItem: ', previousSelectedItem);
      if (previousSelectedItem && clickedItem.properties.id != previousSelectedItem.properties.id)
        this.map.setFilter(`${previousSelectedItem.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], '']);

      // if nothing is selected
      if (!clickedItem) {
        // clear selection data
        this.$store.dispatch('app/updateItemSelected', false);
        this.$store.dispatch('app/updateRightPanel', false);
        this.$store.dispatch('lie2020_1/updateItemSelected', false);
        this.$store.dispatch('lie2020_1/selectItem', null);

        // if selected
      } else {
        // highlight clicked clickedItem
        this.map.setFilter(`${clickedItem.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], clickedItem.properties.id]);

        // update flags
        if(desktopFlag) {
          this.$store.dispatch('app/updateRightPanel', true);
        } else {
          // this.$store.dispatch('app/updateRightPanel', true);
          this.$store.dispatch('lie2020_1/updateLeftPanel', false);
        }
        this.$store.dispatch('app/updateItemSelected', true);
        this.$store.dispatch('lie2020_1/selectItem', clickedItem);

        // center map view on new item
        this.map.flyTo({
          center: clickedItem.geometry.coordinates[0],
          zoom: 17,
        });
      }
    },

    filterMap(map) {
      // if no map object is provided, take no action and return
      if (!map || !this.mapLoadedFlag) return;
      // for each layer
      this.$store.state.lie2020_1.itemGroups[0].layers
          .forEach((layer) => {
            map
                // get source
                .getSource(layer.sourceId)
                // reset data to mirror filters selection
                .setData({
                  "type": "FeatureCollection",
                  "name": layer.id,
                  "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
                  features: this.filteredItems
                      .filter(i => i.layer.id === layer.id),
                })

          });
    },
  },

  watch: {
    /* eslint-disable-next-line no-unused-vars */
    filteredItems(newValue, oldValue) {
      this.filterMap(this.map);
    },
  },


};

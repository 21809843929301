//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

export default {
  name: "Lmi2015InfoPanel",

  computed: {
    ...mapState({
      currentItem: state => {
        return state.lmi2015.selectedItem;
      }
    }),

    rightPanel: {
      get() {
        return this.$store.state.lmi2015.rightPanel;
      },
      set: function (value) {
        this.$store.dispatch('lmi2015/updateRightPanel', value);
      },
    },

    monumentInfoShown: {
      get() {
        return (
            this.rightPanel &&
            this.$store.state.lmi2015.selectedItem &&
            !!this.$store.state.lmi2015.selectedItem['cod_lmi']
        );
      },
      set: function (value) {
        // console.log('app: setMonumentInfoShown: ', value);
        this.$store.dispatch('lmi2015/updateRightPanel', value);
      },
    },
  },

  methods: {
    formatGPSCoord(number) {
      return parseFloat(number).toFixed(6);
    },
    toggleRightPanel() {
      this.$store.dispatch('lmi2015/toggleRightPanel');
    },
    openPhotoDatesDialog() {
      if (this.currentItem && this.currentItem.images.length > 0) this.$store.dispatch("lmi2015/toggleAlbumDatesDialog");
    },
    clearSelection: function () {
      this.toggleRightPanel();
      this.$store.dispatch('lmi2015/clearSelection');
      this.$store.dispatch('app/updateItemSelected', false);
    },
  },
};

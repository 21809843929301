//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';

export default {
  name: "Lmi2015SearchPanel",

  data() {
    return {
      colorCodes: {
        REPREZENTATIV: 'purple-9',
        DOMESTIC_URBAN: 'orange-8',
        DOMESTIC_RURAL: 'red-8',
        RELIGIOS: 'blue-7',
        ADMINISTRATIV: 'yellow-12',
        TRANSPORTURI: 'purple-12',
        PREINDUSTRIAL_PRELUCRATOR: 'lime-13',
        INDUSTRIAL_EXTRACTIV: 'light-green-13',
        INDUSTRIAL_PRELUCRATOR: 'cyan-12',
        INDUSTRIAL_ENERGETIC: 'green-10',
      },
    };
  },
  computed: {
    leftPanel: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.lmi2015.leftPanel;
      }
      ,
      set: function (value) {
        // console.log('app: setLeftPanel: ', value);
        this.$store.dispatch('lmi2015/updateLeftPanel', value);
      },
    },

    selectedItem() {
      return this.$store.state.lmi2015.selectedItem;
    },

    filterText: {
      get() {
        return this.$store.state.lmi2015.filterText || "";
      },
      set: function (value) {
        this.$store.dispatch("lmi2015/setFilterText", value);
      }
    },

    filtruTipPatrimoniu: {
      get() {
        return this.$store.state.lmi2015.filtruTipPatrimoniu || "";
      },
      set(value) {
        this.$store.dispatch("lmi2015/setFiltruTipPatrimoniu", value);
      }
    },

    filtruValoareMon: {
      get() {
        return this.$store.state.lmi2015.filtruValoareMon || "";
      },
      set(value) {
        this.$store.dispatch("lmi2015/setFiltruValoareMon", value);
      }
    },

    filtruScaraMon: {
      get() {
        return this.$store.state.lmi2015.filtruScaraMon || "";
      },
      set(value) {
        this.$store.dispatch("lmi2015/setFiltruScaraMon", value);
      }
    },

    ...mapState({
      monumentDisplayed: (state) => state.lmi2015.monumentDisplayed,
    }),

    monFilteredList() {
      return this.$store.getters["lmi2015/filteredArray"];
    },
    countFilteredList() {
      return this.monFilteredList.length;
    },
    getColorCode(value) {
      return this.colorCodes[value];
    }
  },

  methods: {
    toggleLeftPanel() {
      this.$store.dispatch('lmi2015/toggleLeftPanel');
    },
    selectMonument(monument) {
      // console.log('@searchPanel > selectedItem: ', monument);
      const desktopFlag = this.$q.platform.is.desktop;
      // console.log('@searchPanel > selectedItem >> desktopFlag ', desktopFlag);
      this.$store.dispatch("lmi2015/selectItem", {monument, desktopFlag});
      if(!desktopFlag) this.$store.dispatch('lmi2015/updateLeftPanel', false);
    }
  },

  created: async function () {
    // open the left panel
    // console.log('@searchPanel > created >> desktopFlag ', this.$q.platform.is.desktop);
    if (this.$q.platform.is.desktop) this.leftPanel = true;
  },
};

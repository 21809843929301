//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: [
    'map',
  ],

  computed: {
    items() {
      return this.$store.state.toaletePublice.items;
    },

    filteredUnits() {
      return this.$store.getters["toaletePublice/filteredUnits"];
    },

    filteredServices() {
      return this.$store.getters["toaletePublice/filteredServices"];
    },

    itemsColors() {
      return this.$store.state.toaletePublice.itemsInfoPanelColors;
    },

    selectedItem() {
      return this.$store.state.toaletePublice.selectedItem;
    },

    unitSelected: async (item) => {
      const selectedItem = await this.$store.state.toaletePublice.selectedItem;
      // console.log('listItem: ', item);
      return {
        active: selectedItem ? selectedItem.id === item.id : false,
      };
    },
  },

  methods: {
    // @list select item
    async selectItem(item) {
      const desktopFlag = this.$q.platform.is.desktop;
      // load map object
      // const map = this.map;
      // console.log('@list > selectItem >> item: ', item);
      // deselect previous selection
      const previousSelectedItem = this.$store.state.toaletePublice.selectedItem;
      // console.log('previousSelectedItem: ', previousSelectedItem);
      if (previousSelectedItem && item != previousSelectedItem)
        this.map.setFilter(`${previousSelectedItem.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], '']);
      // if nothing is selected
      if (!item) {
        // clear selection data
        this.$store.dispatch('app/updateItemSelected', false);
        this.$store.dispatch('app/updateRightPanel', false);
        this.$store.dispatch('toaletePublice/updateItemSelected', false);
        this.$store.dispatch('toaletePublice/selectItem', null);

        // if selected
      } else {
        // highlight clicked item
        this.map.setFilter(`${item.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], item.properties.id]);

        // update flags
        if(desktopFlag) {
          this.$store.dispatch('app/updateRightPanel', true);
        } else {
          // this.$store.dispatch('app/updateRightPanel', true);
          this.$store.dispatch('toaletePublice/updateLeftPanel', false);
        }
        this.$store.dispatch('app/updateItemSelected', true);
        this.$store.dispatch('toaletePublice/selectItem', item);

        // center map view on new item
        this.map.flyTo({
          center: item.geometry.coordinates,
          zoom: 15,
        });
      }
    },
  },
};

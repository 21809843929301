//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Mapbox from "mapbox-gl";

export default {
  props: [
    'map',
  ],

  computed: {
    loading: {
      get() {
        return this.$store.state.spatiiPublice.loading;
      },
      set: function (value) {
        this.$store.dispatch('reabilitareTermica/updateLoading', value);
      },
    },

    items() {
      return this.$store.state.reabilitareTermica.items;
    },

    filteredItems() {
      return this.$store.state.reabilitareTermica.items && this.$store.state.reabilitareTermica.items.features ?
          this.$store.state.reabilitareTermica.items.features.filter(i => this.filtruEtape.includes(i.layer.id)) :
          [];
    },

    itemGroups() {
      return this.$store.state.reabilitareTermica.itemGroups;
    },

    itemsColors() {
      return this.$store.state.reabilitareTermica.itemsInfoPanelColors;
    },

    selectedItem() {
      return this.$store.state.reabilitareTermica.selectedItem;
    },

    unitSelected: async (item) => {
      const selectedItem = await this.$store.state.reabilitareTermica.selectedItem;
      // console.log('listItem: ', item);
      return {
        active: selectedItem ? selectedItem.id === item.id : false,
      };
    },

    filtruEtapeToate: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.reabilitareTermica.filtruEtapeToate;
      },
      set: function (value) {
        // console.log('@leftPanel > updateUnitatiFilterToate: ', value);
        this.$store.dispatch('reabilitareTermica/updateItemsFilterToate', {value, map: this.map});
      },
    },

    filtruEtape: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.reabilitareTermica.filtruEtape;
      },
      set: function (value) {
        // console.log('@leftPanel > updateItemsFilter: ', value);
        this.$store.dispatch('reabilitareTermica/updateItemsFilter', {value, map: this.map});
      },
    },

  },

  methods: {
    // @list select item
    async selectItem(clickedItem) {
      const desktopFlag = this.$q.platform.is.desktop;
      // console.log('@searchPanel > clickedItem: ', clickedItem);
      // load map object
      const map = this.map;

      // select corresponding item from map
      // const item = await map.querySourceFeatures(layerId, {
      //   sourceLayer: sourceId,
      //   filter: ['==', ['get', 'id'], clickedItem.properties.id]
      // })[0];
      // console.log('@searchPanel > mapItem: ', item);
      // item.layer = clickedItem.layer;
      // item.layer = {};
      // item.layer.id = layerId;
      // item.layer.source = sourceId;
      // console.log('@searchPanel > mapItem: ', item);

      // de-highlight all layers
      this.$store.state.reabilitareTermica.filtruEtape
          .forEach((lItem) => {
            map.setFilter(`${lItem}_HIGHLIGHT`, ['==', ['get', 'id'], '']);
          });

      // highlight clicked item
      if (clickedItem) map.setFilter(`${clickedItem.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], clickedItem.properties.id]);

      // if nothing is selected
      if (!clickedItem) {
        // clear selection data
        this.$store.dispatch('app/updateItemSelected', false);
        this.$store.dispatch('app/updateRightPanel', false);
        this.$store.dispatch('reabilitareTermica/updateItemSelected', false);
        this.$store.dispatch('reabilitareTermica/selectItem', null);

        // if selected
      } else {
        // highlight clicked clickedItem
        map.setFilter(`${clickedItem.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], clickedItem.properties.id]);

        // update flags
        if (desktopFlag) {
          this.$store.dispatch('app/updateRightPanel', true);
        } else {
          // this.$store.dispatch('app/updateRightPanel', true);
          this.$store.dispatch('reabilitareTermica/updateLeftPanel', false);
        }
        this.$store.dispatch('app/updateItemSelected', true);
        this.$store.dispatch('reabilitareTermica/selectItem', clickedItem);

        map.flyTo({
          center: clickedItem.geometry.coordinates,
          zoom: 17,
        });
      }
    },
  },

  mounted() {
    // console.log('screen: ', this.$q.screen.height);
    const desktopFlag = this.$q.platform.is.desktop;
    if (desktopFlag) {
      const spBox = document.getElementById('mapContainer').offsetHeight;
      const spDD = document.getElementById('rt-sp-descarcare-date').offsetHeight;
      const spFL = document.getElementById('rt-sp-lista-filtre').offsetHeight;
      const spVSmaxHeight = spBox - spDD - spFL - 32;
      // console.log('searchPanel VB height: ', spVSmaxHeight);
      const spVSelement = document.getElementById('rt-sp-vscroll');
      // console.log('vscroll.maxHeight: ', spVSelement.style.maxHeight);
      spVSelement.style.maxHeight = `${spVSmaxHeight}px`;

      // console.log('vscroll.maxHeight: ', spVSelement.style.maxHeight);
      spVSelement.style.display = 'block';
      this.$mount();

    } else {
      const spVSelement = document.getElementById('rt-sp-vscroll');
      spVSelement.style.maxHeight = `${this.$q.screen.height}px`;
      spVSelement.style.display = 'block';
      this.$mount();
    }
  },
};

import { render, staticRenderFns } from "./SearchPanel.vue?vue&type=template&id=71424877&scoped=true&"
import script from "./SearchPanel.vue?vue&type=script&lang=js&"
export * from "./SearchPanel.vue?vue&type=script&lang=js&"
import style0 from "./SearchPanel.vue?vue&type=style&index=0&id=71424877&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71424877",
  null
  
)

export default component.exports
import {QDrawer,QList,QExpansionItem,QCard,QCardSection,QBtn,QItem,QItemSection,QItemLabel,QIcon,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDrawer,QList,QExpansionItem,QCard,QCardSection,QBtn,QItem,QItemSection,QItemLabel,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})

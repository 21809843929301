//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Mapbox from 'mapbox-gl';

export default {
  name: 'SpatiiPubliceSearchPanel',

  props: [
    'map',
    'mapLoadedFlag',
  ],

  computed: {
    isLmiRoute() {
      return this.$route.name === 'spatii-publice';
    },

    loading: {
      get() {
        return this.$store.state.spatiiPublice.loading;
      },
      set: function (value) {
        this.$store.dispatch('spatiiPublice/updateLoading', value);
      },
    },

    leftPanel: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.spatiiPublice.leftPanel;
      },
      set: function (value) {
        // console.log('app: setLeftPanel: ', value);
        this.$store.dispatch('spatiiPublice/updateLeftPanel', value);
      },
    },

    rightPanel: {
      get() {
        return this.$store.state.spatiiPublice.rightPanel;
      },
      set: function (value) {
        this.$store.dispatch('spatiiPublice/updateRightPanel', value);
      },
    },

    currentItem: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.spatiiPublice.selectedItem;
      },
      set: function (value) {
        // console.log('app: setLeftPanel: ', value);
        this.$store.dispatch('spatiiPublice/selectItem', value);
      },
    },

    itemsColors() {
      return this.$store.state.spatiiPublice.itemsInfoPanelColors;
    },

    spatiiAbandonate() {
      return this.$store.getters['spatiiPublice/getSpatiiAbandonate'] ?
          this.$store.getters['spatiiPublice/getSpatiiAbandonate'].features : [];
    },
    spatiiPunctuale() {
      return this.$store.getters['spatiiPublice/getSpatiiPunctuale'] ?
          this.$store.getters['spatiiPublice/getSpatiiPunctuale'].features : [];
    },
    spatiiLiniare() {
      return this.$store.getters['spatiiPublice/getSpatiiLiniare'] ?
          this.$store.getters['spatiiPublice/getSpatiiLiniare'].features : [];
    },
    spatiiSuprafata() {
      return this.$store.getters['spatiiPublice/getSpatiiSuprafata'] ?
          this.$store.getters['spatiiPublice/getSpatiiSuprafata'].features : [];
    },
    selectedItem() {
      return this.$store.state.spatiiPublice.selectedItem;
    },
  },

  methods: {
    selectItem(clickedItem, {layerId, sourceId}) {
      const desktopFlag = this.$q.platform.is.desktop;
      // add layerId and sourceId to item
      const item = clickedItem;
      item.layer = {};
      item.layer.id = layerId;
      item.layer.source = sourceId;
      // console.log('@selectItem > item: ', item);

      // load map object
      const mapObj = this.map;

      // de-highlight all layers
      ['SPATII_ABANDONATE', 'SPATII_PUNCTUALE', 'SPATII_LINIARE', 'SPATII_SUPRAFATA']
          .forEach((item) => {
            mapObj.setFilter(`${item}_HIGHLIGHT`, ['==', ['get', 'id'], '']);
          });

      // highlight clicked item
      if (item) mapObj.setFilter(`${item.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], item.properties.id]);

      // center view on selected item
      if (item.geometry.type === 'Point') {
        // console.log('Geometry Type: ', e.features[0].geometry.type);
        mapObj.flyTo({
          center: item.geometry.coordinates,
          zoom: 15,
        });
      } else if (item.geometry.type === 'LineString') {
        // console.log('Geometry Type: ', item.geometry.type);
        // Geographic coordinates for LineString
        const coordinates = item.geometry.coordinates;
        // Pass the first coordinates in the LineString to `lngLatBounds` &
        // wrap each coordinate pair in `extend` to include them in the bounds
        // result. A variation of this technique could be applied to zooming
        // to the bounds of multiple Points or Polygon geometries - it just
        // requires wrapping all the coordinates with the extend method.
        // console.log('coordinates: ', coordinates);
        const bounds = coordinates.reduce(function (bounds, coord) {
          return bounds.extend(coord);
        }, new Mapbox.LngLatBounds(coordinates[0], coordinates[0]));
        // mapObj.fitBounds(bounds, {
        //   padding: 400
        // });
        mapObj.flyTo({
          center: bounds.getCenter(),
          zoom: 15,
        });
      } else {
        // console.log('Geometry Type: ', item.geometry.type);
        // Geographic coordinates for MultiLineString or Polygon
        const coordinates = clickedItem.geometry.coordinates;
        // console.log('coordinates: ', coordinates);
        const bounds = coordinates[0].reduce(function (bounds, coord) {
          return bounds.extend(coord);
        }, new Mapbox.LngLatBounds(coordinates[0][0], coordinates[0][1]));
        mapObj.flyTo({
          center: bounds.getCenter(),
          zoom: 15,
        });
      }

      // save new selected item to store
      this.$store.dispatch("spatiiPublice/selectItem", item);
      // update flags
      if (desktopFlag) {
        this.$store.dispatch('app/updateRightPanel', true);
      } else {
        this.$store.dispatch('app/updateRightPanel', false);
        this.$store.dispatch('spatiiPublice/updateLeftPanel', false);
      }
      this.$store.dispatch('app/updateItemSelected', true);
    },
  },

};

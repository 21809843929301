//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

export default {
  name: 'SpatiiPubliceInfoPanel',

  computed: {
    ...mapState({
      currentItem: state => {
        return state.spatiiPublice.selectedItem;
      }
    }),

    isLmiRoute() {
      return this.$route.name === 'spatii-publice';
    },

    rightPanel: {
      get() {
        return this.$store.state.spatiiPublice.rightPanel;
      },
      set: function (value) {
        this.$store.dispatch('spatiiPublice/updateRightPanel', value);
      },
    },
  },

  methods: {
    formatGPSCoord(number) {
      return parseFloat(number).toFixed(6);
    },

    toggleRightPanel() {
      this.$store.dispatch('spatiiPublice/toggleRightPanel');
    },

    clearSelection: function () {
      this.toggleRightPanel();
      this.$store.dispatch('spatiiPublice/clearSelection');
      this.$store.dispatch('app/updateItemSelected', false);
    },
  },
};

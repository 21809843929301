//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: [
    'map',
  ],

  computed: {
    loading: {
      get() {
        return this.$store.state.spatiiPublice.loading;
      },
      set: function (value) {
        this.$store.dispatch('infrastructuraSanatate/updateLoading', value);
      },
    },

    itemGroups() {
      return this.$store.state.infrastructuraSanatate.itemGroups;
    },

    filteredUnits() {
      return this.$store.getters["infrastructuraSanatate/filteredUnits"];
    },

    filteredServices() {
      return this.$store.getters["infrastructuraSanatate/filteredServices"];
    },

    unitsColors() {
      return this.$store.state.infrastructuraSanatate.unitsInfoPanelColors;
    },

    servicesColors() {
      return this.$store.state.infrastructuraSanatate.servicesInfoPanelColors;
    },

    countFilteredList() {
      return this?.filteredItems?.length;
    },

    selectedItem() {
      return this.$store.state.infrastructuraSanatate.selectedItem;
    },

    unitSelected: async (item) => {
      const selectedItem = await this.$store.state.infrastructuraSanatate.selectedItem;
      // console.log('listItem: ', item);
      return {
        active: selectedItem ? selectedItem.id === item.id : false,
      };
    },

    filtruUnitatiToate: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.infrastructuraSanatate.filtruUnitatiToate;
      },
      set: function (value) {
        // console.log('@leftPanel > updateUnitatiFilterToate: ', value);
        this.$store.dispatch('infrastructuraSanatate/updateUnitsFilterToate', {map: this.map, value});
      },
    },

    filtruUnitati: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.infrastructuraSanatate.filtruUnitati;
      },
      set: function (value) {
        // console.log('@leftPanel > updateUnitsFilter: ', value);
        this.$store.dispatch('infrastructuraSanatate/updateUnitsFilter', {map: this.map, value});
      },
    },

    filtruServiciiToate: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.infrastructuraSanatate.filtruServiciiToate;
      },
      set: function (value) {
        // console.log('@leftPanel > updateServiciiFilterToate: ', value);
        this.$store.dispatch('infrastructuraSanatate/updateServicesFilterToate', {map: this.map, value});
      },
    },

    filtruServicii: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.infrastructuraSanatate.filtruServicii;
      },
      set: function (value) {
        // console.log('@leftPanel > updateServicesFilter: ', value);
        this.$store.dispatch('infrastructuraSanatate/updateServicesFilter', {map: this.map, value});
      },
    },
  },

  methods: {
    // @list select item
    async selectItem(item) {
      const desktopFlag = this.$q.platform.is.desktop;
      // load map object
      const mapObj = this.map;
      // console.log('@list > selectItem >> map: ', mapObj);

      // deselect previous selection
      const previousSelectedItem = this.$store.state.infrastructuraSanatate.selectedItem;
      // console.log('previousSelectedItem: ', previousSelectedItem);
      if (previousSelectedItem && item != previousSelectedItem) mapObj.setFilter(`${previousSelectedItem.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], '']);

      // if nothing is selected
      if (!item) {
        // clear selection data
        this.$store.dispatch('app/updateItemSelected', false);
        this.$store.dispatch('app/updateRightPanel', false);
        this.$store.dispatch('infrastructuraSanatate/updateItemSelected', false);
        this.$store.dispatch('infrastructuraSanatate/selectItem', null);

        // if selected
      } else {
        // highlight clicked item
        mapObj.setFilter(`${item.layer.id}_HIGHLIGHT`, ['==', ['get', 'id'], item.properties.id]);

        // update flags
        if(desktopFlag) {
          this.$store.dispatch('app/updateRightPanel', true);
        } else {
          // this.$store.dispatch('app/updateRightPanel', true);
          this.$store.dispatch('infrastructuraSanatate/updateLeftPanel', false);
        }
        this.$store.dispatch('app/updateItemSelected', true);
        this.$store.dispatch('infrastructuraSanatate/selectItem', item);

        // center map view on new item
        mapObj.flyTo({
          center: item.geometry.coordinates,
          zoom: 15,
        });
      }
    },
  },
};

//
//
//
//
//
//
//
//
//

import Map from "@/components/infrastructura_sanatate/Map_mapbox";

export default {
  name: "InfrastructuraSanatateView",
  components: {
    Map,
  },
  data () {
    return {
      homepage: false,
    }
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"overflow-x":"hidden"}},[_c('q-expansion-item',{attrs:{"id":"rt-sp-descarcare-date","dense":"","dense-toggle":"","expand-separator":"","icon":"save_alt","label":"Descarcare date"}},[_c('q-card',[_c('q-card-section',{staticClass:"column bg-grey-4"},[_c('q-btn',{attrs:{"flat":"","no-caps":"","label":"Date [SHP @905KB]","align":"between","type":"a","href":"/downloads/reabilitare_termica/b_reabilitare-termica_l.zip","target":"_blank"}})],1)],1)],1),_c('q-expansion-item',{attrs:{"id":"rt-sp-lista-filtre","dense":"","dense-toggle":"","expand-separator":"","icon":"done","label":"Filtrare Etape","default-opened":""}},[_c('q-list',[_c('q-card-section',{staticClass:"column bg-grey-4"},[_c('q-checkbox',{attrs:{"keep-color":"","label":("Toate etapele [" + (_vm.items && _vm.items.features ?
                      _vm.items.features.length : 0) + "]"),"color":_vm.itemsColors['TOATE']},model:{value:(_vm.filtruEtapeToate),callback:function ($$v) {_vm.filtruEtapeToate=$$v},expression:"filtruEtapeToate"}}),_c('q-checkbox',{attrs:{"val":"URMEAZA","label":("Urmeaza [" + (_vm.itemGroups[0].layers ?
                      _vm.itemGroups[0].layers[0].itemsCount : 0) + "]"),"color":_vm.itemsColors['URMEAZA']},model:{value:(_vm.filtruEtape),callback:function ($$v) {_vm.filtruEtape=$$v},expression:"filtruEtape"}}),_c('q-checkbox',{attrs:{"keep-color":"","val":"IN_PROIECTARE","label":("In Proiectare [" + (_vm.itemGroups[0].layers ?
                      _vm.itemGroups[0].layers[1].itemsCount : 0) + "]"),"color":_vm.itemsColors['IN_PROIECTARE']},model:{value:(_vm.filtruEtape),callback:function ($$v) {_vm.filtruEtape=$$v},expression:"filtruEtape"}}),_c('q-checkbox',{attrs:{"keep-color":"","val":"IN_EXECUTIE","label":("In Executie [" + (_vm.itemGroups[0].layers ?
                      _vm.itemGroups[0].layers[2].itemsCount : 0) + "]"),"color":_vm.itemsColors['IN_EXECUTIE']},model:{value:(_vm.filtruEtape),callback:function ($$v) {_vm.filtruEtape=$$v},expression:"filtruEtape"}}),_c('q-checkbox',{attrs:{"keep-color":"","val":"FINALIZAT","label":("Finalizat [" + (_vm.itemGroups[0].layers ?
                      _vm.itemGroups[0].layers[3].itemsCount : 0) + "]"),"color":_vm.itemsColors['FINALIZAT']},model:{value:(_vm.filtruEtape),callback:function ($$v) {_vm.filtruEtape=$$v},expression:"filtruEtape"}}),_c('q-checkbox',{attrs:{"keep-color":"","val":"REZILIAT","label":("Reziliat [" + (_vm.itemGroups[0].layers ?
                      _vm.itemGroups[0].layers[4].itemsCount : 0) + "]"),"color":_vm.itemsColors['REZILIAT']},model:{value:(_vm.filtruEtape),callback:function ($$v) {_vm.filtruEtape=$$v},expression:"filtruEtape"}}),_c('q-checkbox',{attrs:{"keep-color":"","val":"AVIZ_NEGATIV_ISC","label":("Aviz Negativ ISC [" + (_vm.itemGroups[0].layers ?
                      _vm.itemGroups[0].layers[5].itemsCount : 0) + "]"),"color":_vm.itemsColors['AVIZ_NEGATIV_ISC']},model:{value:(_vm.filtruEtape),callback:function ($$v) {_vm.filtruEtape=$$v},expression:"filtruEtape"}})],1)],1)],1),_c('q-expansion-item',{attrs:{"dense":"","dense-toggle":"","expand-separator":"","icon":"scatter_plot","label":("Imobile [" + (_vm.filteredItems.length) + "]"),"default-opened":""}},[_c('q-virtual-scroll',{staticClass:"bg-grey-4",staticStyle:{"max-height":"100px"},attrs:{"id":"rt-sp-vscroll","items":_vm.filteredItems,"items-size":_vm.filteredItems.length,"separator":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,staticClass:"row items-start no-padding",class:{active: (_vm.selectedItem ? item.properties.id === _vm.selectedItem.properties.id : false)},attrs:{"dense":"","clickable":""},on:{"click":function($event){return _vm.selectItem(item)}}},[_c('q-item-section',{staticClass:"q-pa-xs no-border no-margin",staticStyle:{"max-width":"40px"}},[_c('q-item-label',{staticClass:"q-pa-xs",staticStyle:{"min-height":"20px"}},[_vm._v(_vm._s(index + 1)+".")])],1),_c('q-item-section',{staticClass:"column q-pt-xs"},[_c('q-item-label',{staticClass:"q-pa-xs",staticStyle:{"min-height":"20px"}},[_vm._v(" "+_vm._s(item['properties']['adresa'])+" ")]),_c('q-item-label',{staticClass:"text-caption text-grey-7 q-pa-xs no-border no-margin",staticStyle:{"min-height":"20px"}},[_vm._v(" "+_vm._s(item['properties']["sector"])+" ")])],1),_c('q-item-section',{staticClass:"q-pa-xs no-border no-margin",staticStyle:{"max-width":"32px"}},[_c('q-icon',{attrs:{"name":"fiber_manual_record","size":"24px","color":_vm.itemsColors[item.layer.id]}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
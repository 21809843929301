//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InfoPanel',

  methods: {
    formatGPSCoord(number) {
      return parseFloat(number).toFixed(6);
    },
  },

  computed: {
    currentItem() {
      return this.$store.state.infrastructuraSanatate.selectedItem;
    }
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "AlbumSections",

  data() {
    return {
      maximizedToggle: true,
    };
  },

  computed: {
    paDialog: {
      get() {
        return this.$store.state.lmi2015.albumSectionsDialog;
      },
      set: function () {
        this.$store.dispatch("lmi2015/toggleAlbumSectionsDialog");
      }
    },

    photoAlbums() {
      return this.$store.getters["lmi2015/getSelectedItemPhotoAlbums"];
    },

    selectedDate() {
      // console.log('selectedDate()...');
      // return this.$store.getters["lmi2015/getSelectedPhotoDate"];
      return this.$store.state.lmi2015.selectedItem.albumDate;
    },

    currentDateAlbum() {
      const returnItem = this.$store.state.lmi2015.selectedItem && this.$store.state.lmi2015.selectedItem.images.length > 0 ?
          this.photoAlbums.filter(item => item.date === this.$store.state.lmi2015.selectedItem.albumDate)[0].sections : [];
      // console.log('@Carousel.vue :: @currentAlbumSection >> ', returnItem);
      return returnItem;
    },
  },

  methods: {

    openNext: function (selectedSection) {
      // console.log('@AlbumSections.vue :: @openNext >> selected section: ', selectedSection);
      // set new section
      this.$store.dispatch("lmi2015/setAlbumSelectedSection", selectedSection);
      // next step for selected date
      // console.log('@AlbumSections.vue :: @openAlbumPhotoGallery');
      this.$store.dispatch("lmi2015/toggleAlbumPhotoGalleryDialog");

    },
  },

  mounted() {
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "AlbumDates",

  data() {
    return {
      maximizedToggle: true,
    };
  },

  components: {},

  computed: {
    dialog: {
      get() {
        return this.$store.state.lmi2015.albumDatesDialog;
      },
      set: function () {
        this.$store.dispatch("lmi2015/toggleAlbumDatesDialog");
      }
    },

    photoAlbums() {
      return this.$store.getters["lmi2015/getSelectedItemPhotoAlbums"];
    },
  },

  methods: {
    selectedDateAlbum(selectedDate) {
      // console.log('@AlbumDates.vue :: @selectedDateAlbum >> selected date: ', selectedDate);
      return this.photoAlbums.filter(item => item.date === selectedDate)[0];
    },

    openAlbumSections: (vueStore, selectedDate) => {
      // console.log('@AlbumDates.vue :: @openAlbumSections >> selectedDate: ', selectedDate);
      vueStore.dispatch("lmi2015/toggleAlbumSectionsDialog", selectedDate);
    },

    openAlbumPhotoGallery: (vueStore) => {
      // console.log('@AlbumDates.vue :: @openAlbumPhotoGallery');
      vueStore.dispatch("lmi2015/toggleAlbumPhotoGalleryDialog");
    },

    openNext: function (selectedDate) {
      // console.log('@AlbumDates.vue :: @openNext >> selected date: ', selectedDate);
      // set new date
      this.$store.dispatch("lmi2015/setAlbumSelectedDate", selectedDate);

      // next step for selected date
      // console.log('selectedDateAlbum: ', this.selectedDateAlbum(selectedDate));
      const sectionsLength = this.selectedDateAlbum(selectedDate).sections.length;
      // console.log('@AlbumDates.vue :: @openNext >> selectedItem.PhotoAlbums.sections.length: ', sectionsLength);
      if (sectionsLength === 1) {
        this.openAlbumPhotoGallery(this.$store);
      } else {
        this.openAlbumSections(this.$store);
      }
    },

  },
}

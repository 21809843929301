//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchPanel from '../components/lmi_2015/SearchPanel';
import InfoPanel from '../components/lmi_2015/InfoPanel';
import Map from "../components/lmi_2015/Map_mapbox";
import Lmi2015AlbumDates from '../components/lmi_2015/AlbumDates';
import Lmi2015AlbumSections from '../components/lmi_2015/AlbumSections';
import Lmi2015AlbumPhotoGallery from '../components/lmi_2015/AlbumPhotoGallery';

export default {
  name: "Lmi2015",

  components: {
    SearchPanel,
    InfoPanel,
    Map,
    Lmi2015AlbumDates,
    Lmi2015AlbumSections,
    Lmi2015AlbumPhotoGallery,
  },

  computed: {
    isLmiRoute() {
      return this.$route.name === 'lmi-2015';
    },
  },
}
